<template>
    <dialog
        ref="dialogRef"
        class="max-w-full md:max-w-fit w-full md:w-auto m-0 focus-visible:outline-0 bg-white rounded-b-none md:rounded-b-xl rounded-xl relative"
        :class="{ 'mt-auto md:m-auto': !inline }"
        @mousedown="handleDialogMouseDown">
        <slot></slot>
    </dialog>
</template>

<script setup>
// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/dialog#see_also
// native html dialog element emits, "cancel" on ESC press, which must be listened by parent component to for dismissing
import { onMounted, onUnmounted, useTemplateRef, watch } from "vue";

const { inline } = defineProps({
    inline: { type: Boolean, default: false },
});

const dialogRef = useTemplateRef("dialogRef");

const emit = defineEmits(["close"]);

const handleDialogMouseDown = (event) => {
    if (event.target === dialogRef.value) {
        dialogRef.value?.close();
        // close event must be listened by parent to handle dialog click outside to close
        emit("close");
    }
};

onMounted(() => {
    watch(
        () => inline,
        (newValue) => {
            if (newValue) {
                dialogRef.value?.close();
                dialogRef.value?.show();
            } else {
                dialogRef.value?.close();
                dialogRef.value?.showModal();
            }
        },
        { immediate: true },
    );
});
onUnmounted(() => {
    dialogRef.value?.close();
});
</script>

<style scoped>
dialog::backdrop {
    background-color: #000;
    opacity: 0.4;
}
</style>
